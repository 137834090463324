import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping";
import moment from "moment";

export const TABLE_COLUMNS = {
    "Article_ID" : `${updateArticleName(false)} ID`,
    "Style_ID": "Style ID",
    "Color_ID": "Color ID",
    "Style": "Style Description",
    "Style_Color": "Style Color",
    "Global_Color": "Global Color",
    "Level1": levelFiltersLabelFormatter("level1"),
    "Level2": levelFiltersLabelFormatter("level2"),
    "Level3": levelFiltersLabelFormatter("level3"),
    "Level4": levelFiltersLabelFormatter("level4"),
    "Level5": levelFiltersLabelFormatter("level5"),
    "Level6": levelFiltersLabelFormatter("level6"),
    "Level7": levelFiltersLabelFormatter("level7"),
    "Factory_Type": "Factory Type",
    "Store_Group" : "Store Groups",
    "Product_Profile": "Product Profile Mapped",
    "DC": "DC Mapped",
    "Old_Style_Mapped": "Old Style Mapped",
    "Allocation_Status": "Allocation Status",
    "Modified_by": "Modified By",
    "From_Date": "From Date",
    "Upto_Date": "Upto Date",
}

export const ALLOCATION_REQUIRED_OPTIONS = [{
    label: "Active",
    value: "active"
  },
  {
    label: "Deactivated",
    value: "deactivated"
  },
  {
    label: "Paused",
    value: "paused"
  }]

  export const EXCEL_KEYS = ["article", "style_code", "color_code", "style_name", "color_desc", "color", "l1_name", "l2_name", "l3_name", "l4_name", "l5_name", "l6_name", "l7_name","factory_type", "store_group_names", "product_profiles", "dcs_count", "related_article", "allocation_status", "updated_by", "from_date", "to_date",]

  export const formatExcelData = (data) => {
    let excelData = [];
    data.forEach(item => {
      excelData.push({
        "article": item.article,
        "style_code": item.style_code,
        "color_code": item.color_code,
        "style_name": item.style_name,
        "color_desc": item.color_desc,
        "color": item.color,
        "l1_name": item.l1_name,
        "l2_name": item.l2_name,
        "l3_name": item.l3_name, 
        "l4_name": item.l4_name, 
        "l5_name": item.l5_name, 
        "l6_name": item.l6_name, 
        "l7_name": item.l7_name, 
        "factory_type": item.factory_type,
        "store_group_names": item?.storegroup_flag || item?.plan_data_flag ? `${item.store_group_names}` : "", 
        "product_profiles": item.product_profiles, 
        "dcs_count": item.dcs_count, 
        "related_article": item.related_article, 
        "allocation_status": item.allocation_status, 
        "updated_by": item.updated_by, 
        "from_date": item.from_date ? moment(item.from_date).format("MM-DD-YYYY") : "", 
        "to_date": item.to_date ? moment(item.to_date).format("MM-DD-YYYY") : "", 
      })
    })
    return excelData;
  }